<template>
	<div class="interventions-home" v-if="page">
		<h2 class="text-secondary">{{ page.title }}</h2>

		<div class="row mb-5">
			<div class="col-12 col-lg-7 order-1 order-lg-0">
				<div v-html="page.content"/>
			</div>
			<div class="col-12 col-lg-5 order-0 order-lg-1">
				<MediaEmbed :page="page" />
			</div>
		</div>

		<Themes button-label="Lees verder" @click-theme="chooseTheme" slug="kennisbank" />
	</div>
</template>

<script>
    // @ is an alias to /src
    import Themes from '@/components/Themes.vue';
    import api from '@/data/api'
    import {themeMixin} from "@/mixins/chooseThemeMixin";
	import MediaEmbed from "@/components/MediaEmbed";

    export default {
        name: 'Kennisbank',
        components: {MediaEmbed, Themes},
        mixins: [themeMixin],
        data() {
            return {
                pageTitle: 'Kennisbank',
                page: null
            }
        },
        mounted() {
            api.fetchPage(5576).then(response => {
                this.page = response;
            });

            const breadcrumbs = [
                {
                    'label': 'Home',
                    'path': '/'
                },
                {
                    'label': 'Kennisbank',
                },
            ];

            this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
        }
    }
</script>
